.MuiButton-root {
  border-radius: 0px !important;
}

.MuiButton-sizeSmall {
  background: #111120 !important;
  border-radius: 5px !important;
  font-size: 10px !important;
  height: 24px !important;
  color: #93a3af !important;
  line-height: 88.8% !important;
  letter-spacing: 0.09em !important;
}

.MuiButton-sizeSmall:hover {
  color: #fff !important;
}

.MuiButton-outlinedPrimary {
  color: white !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.09rem !important;
  line-height: 14px !important;
  padding: 10px 20px !important;
}

.MuiButton-containedPrimary {
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.09rem !important;
  line-height: 14px !important;
  padding: 10px 20px !important;
}

.MuiListItemText-primary {
  font-weight: bold !important;
  line-height: 88.8% !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.09rem !important;
  color: #93a3af !important;
}

.MuiListItemIcon-root svg {
  color: #93a3af !important;
}

.active svg {
  color: #2b43c2 !important;
}

.MuiListItem-button.active {
  background: #0e111d !important;
}

.MuiListItem-button:hover {
  background: #0e111d !important;
}

.active .MuiListItemText-primary {
  color: #fff !important;
}

.shiryo-avatar:hover {
  background: rgba(20, 22, 40, 1) !important;
}

#mintAmountInput {
  text-align: center !important;
}

.numberFormat {
  background: black;
  border: none;
  color: white;
  text-align: center;
  font-family: Play;
  outline: none;
  line-height: 88.8%;
  font-size: 14px;
  letter-spacing: 0.09rem;
  color: white;
  width: 100% !important;
}

.MuiSelect-iconOutlined {
  color: #93a3af !important;
}

.rpb-root {
  margin: 0 auto !important;
  width: 100% !important;
}